const hasAttributes = ({ attributesById = [], attributesByKey = [], attributeRanges = [] }: Partial<TSearchRequest>) =>
  attributesById.length || attributesByKey.length || attributeRanges.length;

const hasQuery = (searchRequest: TSearchRequest) => searchRequest.searchQuery !== '';

const hasNonValuableAttributes = (searchRequest: TSearchRequest) => {
  interface AttributeValue {
    isDefault: boolean;
  }

  const nonValuableAttributeKeys = searchRequest.attributes
    ? Object.keys(searchRequest.attributes).filter(
        (key: string) =>
          (searchRequest.seoFriendlyAttributes?.indexOf(key) ?? -1) < 0 &&
          !searchRequest.attributes[key].every((value: AttributeValue) => value.isDefault),
      )
    : [];

  return nonValuableAttributeKeys.length > 0;
};

const getRobotsContent = (
  searchRequest: TSearchRequest,
  hasResults: boolean,
  isFetchResultsOnceAllCatEnabled: boolean,
) => {
  if (isFetchResultsOnceAllCatEnabled) {
    return (hasAttributes(searchRequest) || hasQuery(searchRequest)) &&
      (!hasResults || hasNonValuableAttributes(searchRequest))
      ? 'noindex, follow'
      : 'index, follow';
  } else {
    return (hasAttributes(searchRequest) || hasQuery(searchRequest)) && !hasResults
      ? 'noindex, follow'
      : 'index, follow';
  }
};

export default getRobotsContent;
