/* eslint-disable jsx-a11y/click-events-have-key-events,no-shadow */
import React, { useState, useEffect } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import Heading from '@/client/components/FilterSidebar/atoms/Heading';
import TreeItem from '@/client/components/FilterSidebar/atoms/TreeItem';

export interface CategoryTreeFacetProps {
  categories: TFacetCategory[];
  inputKey: string;
  label: string;
  isMobile: boolean;
  searchRequestObject: TSearchRequest;
  maxL2Categories?: number;
}

type FormattedCategory = TFacetCategory & {
  items: TFacetCategory[];
  selected: boolean;
};

const DEFAULT_MAX_L2_CATEGORIES = 4;

const getFormattedCategories = (categories: TFacetCategory[]): FormattedCategory[] =>
  Array.isArray(categories)
    ? categories.reduce<FormattedCategory[]>((acc, item) => {
        if (item.parentId) {
          const parentCategory = acc.find((cat) => cat.id === item.parentId);
          if (parentCategory) {
            parentCategory.items.push(item);
          }
        } else {
          acc.push({
            ...item,
            items: [],
            selected: false,
          });
        }
        return acc;
      }, [])
    : [];

const sortL2sAlphabetically = (a, b) => a.label.localeCompare(b.label);

const CategoryTreeFacet: React.FC<CategoryTreeFacetProps> = ({
  categories: categoriesFromFacet,
  inputKey,
  label,
  isMobile,
  searchRequestObject,
  maxL2Categories = DEFAULT_MAX_L2_CATEGORIES,
}) => {
  const { t } = useI18nContext();
  const formattedCategories = getFormattedCategories(categoriesFromFacet);
  const isAnyL1Selected = Array.isArray(formattedCategories) ? formattedCategories.some((l1) => l1.selected) : false;
  const hideIndex = maxL2Categories;
  const [isExpanded, setIsExpanded] = useState<number[]>([]);
  const [categories, setCategories] = useState(formattedCategories);

  useEffect(() => {
    if (!isExpanded.length) {
      return;
    }

    setCategories((prevCategories) =>
      prevCategories.map((l1) => ({
        ...l1,
        items: isExpanded.includes(l1.id) ? l1.items.sort(sortL2sAlphabetically) : l1.items,
      })),
    );
  }, [isExpanded]);

  const toggleExpanded = (l1CategoryId: number) => {
    if (isExpanded.includes(l1CategoryId)) {
      setIsExpanded((prevState) => prevState.filter((id) => id !== l1CategoryId));
    } else {
      setIsExpanded((prevState) => [...prevState, l1CategoryId]);
    }
  };

  const isOpenCheck = (index: number) => {
    let isOpen = index === 0;

    if (isMobile) {
      isOpen = isAnyL1Selected;
    }

    return isOpen;
  };

  return (
    <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Filter' })}>
      <Heading.H5>{t(`facets.${inputKey}`, label)}</Heading.H5>
      <ul className={getSuitCSSClasses({ namespace: 'hz', name: 'Tree-list' })}>
        {categories.map(({ label: l1CategoryLabel, items, id: l1CategoryId, key: l1CategoryKey }, index) => (
          <div
            key={l1CategoryKey}
            id={l1CategoryId.toString()}
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Tree-list-item',
            })}
          >
            <TreeItem.LevelOne
              categories={{
                l1Category: {
                  key: l1CategoryKey,
                  id: l1CategoryId,
                },
              }}
              isOpen={isOpenCheck(index)}
              text={l1CategoryLabel}
              searchRequestObject={searchRequestObject}
            />
            {items.map(({ label: itemLabel, histogramCount, id: l2CategoryId, key: l2CategoryKey }, idx) => (
              <TreeItem.LevelTwo
                categories={{
                  l1Category: {
                    key: l1CategoryKey,
                    id: l1CategoryId,
                  },
                  l2Category: {
                    key: l2CategoryKey,
                    id: l2CategoryId,
                  },
                }}
                key={l2CategoryKey}
                text={itemLabel}
                count={histogramCount}
                hidden={idx >= hideIndex && !isExpanded.includes(l1CategoryId)}
                searchRequestObject={searchRequestObject}
              />
            ))}
            {items.length > maxL2Categories && (
              <li
                role="presentation"
                className={getSuitCSSClasses({ namespace: 'hz', name: 'Level-two', modifiers: ['more'] })}
                onClick={() => toggleExpanded(l1CategoryId)}
              >
                {isExpanded.includes(l1CategoryId) ? t('facets.less') : t('facets.more')}
              </li>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default CategoryTreeFacet;
