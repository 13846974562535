import React, { Fragment } from 'react';
import { Icon, BreadcrumbItem, Breadcrumbs } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { getFormattedNumber } from '@ecg-marktplaats/aurora-js-formatters';
import CorrectedKeyword from './CorrectedKeyword';

type BreadCrumbItem = {
  link: string;
  text?: string;
  shouldMask?: boolean;
};

interface ResultTextProps {
  breadcrumbs: {
    items: BreadCrumbItem[];
    resultCount: number;
  };
  correctedQuery: string;
  originalQuery?: string;
  searchRequestObject: TSearchRequest;
}

export interface BreadCrumbProps {
  breadcrumbs: {
    items: BreadCrumbItem[];
    resultCount: number;
  };
  correctedQuery: string;
  originalQuery?: string;
  searchRequestObject: TSearchRequest;
}

const ResultsText: React.FC<ResultTextProps> = ({
  breadcrumbs,
  correctedQuery,
  originalQuery = '',
  searchRequestObject,
}) => {
  const { resultCount } = breadcrumbs;
  const searchTerm = correctedQuery || originalQuery;
  const formattedResultCount = getFormattedNumber(resultCount);

  return (
    <>
      <Trans
        tagName="span"
        i18nKey="breadcrumbs.results"
        count={resultCount}
        formattedResultCount={formattedResultCount}
      />
      {searchTerm && (
        <>
          &nbsp;
          <Trans tagName="span" i18nKey="breadcrumbs.for" searchTerm={searchTerm} />
        </>
      )}
      <CorrectedKeyword
        correctedQuery={correctedQuery}
        searchRequestObject={searchRequestObject}
        originalQuery={originalQuery}
      />
    </>
  );
};

const Breadcrumb: React.FC<BreadCrumbProps> = ({
  breadcrumbs,
  correctedQuery = '',
  originalQuery = '',
  searchRequestObject,
}) => (
  <Breadcrumbs>
    <BreadcrumbItem link="/" data-testid="breadcrumb-link">
      <Trans tagName="span" i18nKey="breadcrumbs.home" />
    </BreadcrumbItem>
    {breadcrumbs?.items.length === 0 && (
      <BreadcrumbItem>
        <Trans tagName="span" i18nKey="breadcrumbs.allPlatform" />
        <Icon name="arrow-right" size="m" color="text-secondary" />
      </BreadcrumbItem>
    )}

    {breadcrumbs?.items.length > 0 && (
      <>
        {breadcrumbs.items.map(({ link, text: itemText, shouldMask }) => (
          <Fragment key={itemText}>
            <BreadcrumbItem key={itemText} link={link} shouldMask={shouldMask} data-testid="breadcrumb-link">
              {itemText}
            </BreadcrumbItem>
            &nbsp;
          </Fragment>
        ))}
      </>
    )}

    <BreadcrumbItem data-testid="breadcrumb-last-item">
      <ResultsText
        breadcrumbs={breadcrumbs}
        correctedQuery={correctedQuery}
        originalQuery={originalQuery}
        searchRequestObject={searchRequestObject}
      />
    </BreadcrumbItem>
  </Breadcrumbs>
);

export default Breadcrumb;
