import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionBody, AccordionItem, AccordionHeader, Title5 } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import AttributeGroupSingleSelectFacet from '@/client/components/FilterSidebar/molecules/AttributeGroupSingleSelectFacet';
import AttributeGroupMultiSelectFacet from '@/client/components/FilterSidebar/molecules/AttributeGroupMultiSelectFacet';
import AttributeGroupMultiSelectButtonsFacet from '@/client/components/FilterSidebar/molecules/AttributeGroupMultiSelectButtonsFacet';
import AttributeToggleGroupFacet from '@/client/components/FilterSidebar/molecules/AttributeToggleGroupFacet';
import QueryFilter from '@/client/components/FilterSidebar/molecules/QueryFilter';
import CategoryTreeFacet from '@/client/components/FilterSidebar/molecules/CategoryTreeFacet';
import AttributeRangeFacetDropdown from '@/client/components/FilterSidebar/molecules/AttributeRangeFacetDropdown';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { transformRangeOptions, transformFilterGroups } from '../utils/carsFilters';
import {
  PRICE_TYPE_LEASE_ATTRIBUTE_VALUE_ID,
  PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID,
} from '@/constants/attributes/nonStandards';

type TProps = {
  facets: TFacet[];
  title?: string;
  isMobile?: boolean;
  searchRequestObject: TSearchRequest;
  sharedDomainCookie?: string;
  searchDistanceOptions?: { name: string; value: number }[];
  totalResults: number;
};

const DEFAULT_MAX_ATTRIBUTE_VALUES = 6;
const OPTIONS_MAX_ATTRIBUTE_VALUES = 8;
const MAX_L2_CATEGORIES = 8;

const RegularCarsFilters = ({ facets, isMobile = false, searchRequestObject, totalResults }: TProps) => {
  const { t } = useI18nContext();
  let rangeOptions = {};
  const leasePriceOnly =
    searchRequestObject.attributesById.includes(PRICE_TYPE_LEASE_ATTRIBUTE_VALUE_ID) &&
    !searchRequestObject.attributesById.includes(PRICE_TYPE_SALE_ATTRIBUTE_VALUE_ID);
  const rangeFilters = facets.filter((facet) => facet.type === 'AttributeRangeFacet');
  rangeOptions = transformRangeOptions(rangeFilters, t, leasePriceOnly);
  const facetGroups = transformFilterGroups(facets);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  const clickHandler = isMobile
    ? () => {}
    : (index) => {
        const newIndex = openAccordionIndex === index ? -1 : index;
        setOpenAccordionIndex(newIndex);
      };

  useEffect(() => {
    const expandedAccordion = document.querySelector('.hz-Filters .hz-AccordionItem-expanded');
    const yOffset = 136;

    if (openAccordionIndex > 0 && expandedAccordion) {
      expandedAccordion.scrollIntoView();
      const top = expandedAccordion.getBoundingClientRect().top + window.pageYOffset - yOffset;
      window.scrollTo({ top });
    }
  }, [openAccordionIndex]);

  return (
    <>
      {Object.keys(facetGroups).map((group, index) => (
        <Accordion key={group} className={getSuitCSSClasses({ namespace: 'hz', name: 'AccordionCustom' })}>
          <AccordionItem
            className={index === openAccordionIndex || isMobile ? 'hz-AccordionItem-expanded' : ''}
            expanded={isMobile}
          >
            <AccordionHeader onClick={() => clickHandler(index)}>
              <Title5>{t(`facets.${group}`)}</Title5>
            </AccordionHeader>
            <AccordionBody onClick={(event) => event.stopPropagation()}>
              {facetGroups[group].map(
                ({ key, attributeGroup, label, range, categories, singleSelect, type, collapsable }) => {
                  switch (type) {
                    case 'AttributeGroupFacet':
                      if (key === 'body') {
                        return (
                          <AttributeGroupMultiSelectButtonsFacet
                            key={key}
                            label={label}
                            attributeGroup={attributeGroup}
                            inputKey={key}
                            searchRequestObject={searchRequestObject}
                            preventScroll
                          />
                        );
                      }
                      if (key === 'priceType') {
                        return (
                          <AttributeToggleGroupFacet
                            key={key}
                            label={label}
                            attributeGroup={attributeGroup}
                            inputKey={key}
                            searchRequestObject={searchRequestObject}
                            preventScroll
                          />
                        );
                      }
                      if (singleSelect) {
                        return (
                          <AttributeGroupSingleSelectFacet
                            key={key}
                            label={label}
                            attributeGroup={attributeGroup}
                            inputKey={key}
                            searchRequestObject={searchRequestObject}
                            preventScroll
                          />
                        );
                      }
                      return (
                        <AttributeGroupMultiSelectFacet
                          key={key}
                          label={label}
                          attributeGroup={attributeGroup}
                          attributeGroupDialog={facets.find((facet) => facet.key === key)?.attributeGroup}
                          inputKey={key}
                          searchRequestObject={searchRequestObject}
                          totalResults={totalResults}
                          isMobile={isMobile}
                          collapsable={collapsable}
                          maxAttributeValues={
                            key === 'options' ? OPTIONS_MAX_ATTRIBUTE_VALUES : DEFAULT_MAX_ATTRIBUTE_VALUES
                          }
                          preventScroll
                        />
                      );
                    case 'AttributeRangeFacet':
                      return (
                        <Fragment key={key}>
                          <AttributeRangeFacetDropdown
                            key={key}
                            label={label}
                            labelKey={rangeOptions[key].labelKey}
                            attributeKey={key}
                            options={rangeOptions[key].options}
                            range={range}
                            searchRequestObject={searchRequestObject}
                            preventScroll
                            isMobile={isMobile}
                          />
                        </Fragment>
                      );
                    case 'CategoryTreeFacet':
                      return (
                        <CategoryTreeFacet
                          key={key}
                          categories={categories}
                          inputKey={key}
                          label={label}
                          isMobile={false}
                          searchRequestObject={searchRequestObject}
                          maxL2Categories={MAX_L2_CATEGORIES}
                        />
                      );
                    case 'query':
                      return <QueryFilter inputKey={key} searchRequestObject={searchRequestObject} />;
                    default:
                      return null;
                  }
                },
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
};

export default RegularCarsFilters;
