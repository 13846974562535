import formatAttributeRange from '@/client/utils/formatters/formatBasedOnKey';
import distanceKmFormatter from '@/client/utils/formatters/distanceKmFormatter';
import { attributeKeys } from '@/constants/attributes/nonStandards';
import TRAITS from '@/constants/traits';
import CATEGORIES_L1 from '@/constants/categories';
import { I18nContextType } from '@ecg-marktplaats/js-react-i18n';

const attributeKeysToShowLabelFor = ['numberOfDoors', 'numberOfSeats', 'numberOfSeatsBE'];

type TChips = {
  type?: string;
  key?: string;
  label?: string;
  linkProps: Record<string, unknown>;
}[];

const filterChipsHelper = (
  searchRequestObject: Partial<TSearchRequest>,
  categoriesById: TCategories,
  facets: TFacet[],
  t: I18nContextType,
): TChips => {
  const findAttributeLabel = (attrKey: string) =>
    facets.filter((f) => f.key === attrKey).reduce((_m, v) => t(`facets.${v.key}`, v.label), '');

  // The range label can be a string or an object with from and to keys. Therefore, we need to handle both cases.
  const convertRangeFacetToLabel = (key: string, facet: TRangeFacet, from?: number, to?: number) => {
    const formattedRange = {
      from:
        from && facet.options?.from?.find(({ value }) => value === from)
          ? facet.options.from.find(({ value }) => value === from)?.label
          : formatAttributeRange(key, from, false, t),
      to:
        to && facet.options?.to.find(({ value }) => value === to)
          ? facet.options.to.find(({ value }) => value === to)?.label
          : formatAttributeRange(key, to, false, t),
    };

    let label: string;
    if (typeof from !== 'undefined' && typeof to !== 'undefined') {
      label = `${formattedRange.from} - ${formattedRange.to}`;
    } else if (typeof from !== 'undefined') {
      label = t('viewControls.chips.rangeFrom', formattedRange);
    } else {
      label = t('viewControls.chips.rangeTo', formattedRange);
    }

    return label;
  };

  const parentCategoryIsCars = (parentCategory: TCategory) => {
    return parentCategory.id === CATEGORIES_L1.CARS;
  };

  const removeCarModelAttribute = (attributes: TAttributeHierarchy) => {
    const { ...remainingAttributes } = attributes;
    delete remainingAttributes[attributeKeys.CAR_MODEL];
    return remainingAttributes;
  };

  const chips = [] as TChips;
  const {
    categories: { l1Category, l2Category },
    distance,
    traits,
    attributes,
    attributeRanges,
  } = searchRequestObject as TSearchRequest;

  if (l1Category) {
    const label = categoriesById[l1Category.id].name;
    chips.push({
      type: 'l1category',
      label,
      key: l1Category.key,
      linkProps: {
        categories: {},
        removeAllFilters: !searchRequestObject.searchQuery && !searchRequestObject.seller,
        removeAllAttributes: true,
      },
    });
  }

  if (l2Category) {
    const label = categoriesById[l2Category.id].name;

    if (parentCategoryIsCars(l1Category)) {
      chips.push({
        type: 'l2category',
        label,
        key: l2Category.key,
        linkProps: {
          categories: {
            l1Category: {
              key: l1Category.key,
              id: l1Category.id,
            },
          },
          // Do not remove attributes when closing the L2 chip within the Cars category, as attributes are shared.
          removeAllAttributes: false,
          searchRequestObject: {
            ...searchRequestObject,
            // Explicitly remove the car model attribute for car categories, as it is specific to the L2.
            attributes: removeCarModelAttribute(attributes),
          },
        },
      });
    } else {
      chips.push({
        type: 'l2category',
        label,
        key: l2Category.key,
        linkProps: {
          categories: {
            l1Category: {
              key: l1Category.key,
              id: l1Category.id,
            },
          },
          removeAllAttributes: true,
        },
      });
    }
  }

  if (distance && distance.distanceMeters && distance.postcode) {
    const kms = distanceKmFormatter(distance.distanceMeters);
    const label = t('filters.distance', { kms });

    chips.push({
      type: 'attributes',
      key: 'attrchip-distance',
      label,
      linkProps: {
        distance: {},
      },
    });
  }

  if (traits && traits.includes(TRAITS.PROFILE)) {
    const label = t('filters.entrepreneurs');
    chips.push({
      label,
      linkProps: {
        searchRequestObject: {
          ...searchRequestObject,
          traits: [],
        },
      },
    });
  }

  Object.keys(attributes).forEach((key) => {
    const attributeList = Array.isArray(attributes[key]) ? attributes[key] : [attributes[key]];
    attributeList.forEach((attribute: TAttribute) => {
      if (attribute.attributeValueId) {
        if (attributeKeysToShowLabelFor.includes(key)) {
          chips.push({
            type: 'attributes',
            key: `attrchip-${attribute.attributeValueId}`,
            label: `${findAttributeLabel(key)}: ${attribute.attributeValueLabel}`,
            linkProps: {
              attributes: { [key]: attribute },
              isSelected: true,
            },
          });
        } else {
          chips.push({
            type: 'attributes',
            key: `attrchip-${attribute.attributeValueId}`,
            label: attribute.attributeValueLabel,
            linkProps: {
              attributes: { [key]: attribute },
              isSelected: true,
            },
          });
        }
      } else if (key === attributeKeys.OFFERED_SINCE && attribute.isDefault === false) {
        chips.push({
          type: 'attributes',
          key: `attrchip-${key}`,
          label: t(`facets.${attribute.attributeValueKey}`),
          linkProps: {
            attributes: { [key]: attribute },
            isSelected: true,
          },
        });
      } else if (
        key !== attributeKeys.OFFERED_SINCE &&
        attribute.attributeValueId === null &&
        key !== attributeKeys.LANGUAGE
      ) {
        // Text attributes
        chips.push({
          type: 'attributes',
          key: `attrchip-${key}`,
          label: attribute.attributeValueLabel,
          linkProps: {
            attributes: { [key]: attribute },
            isSelected: true,
          },
        });
      }
    });
  });

  attributeRanges.forEach((range) => {
    const key = range.attributeKey;
    const rangeFacet = facets.find((facet) => facet.key === key) as TRangeFacet;
    chips.push({
      key: `rangechip-${key}`,
      label: convertRangeFacetToLabel(key, rangeFacet, range.from, range.to),
      linkProps: {
        rangeAttributes: attributeRanges.filter((r) => r.attributeKey !== key),
        isSelected: true,
      },
    });
  });

  return chips;
};

export default filterChipsHelper;
